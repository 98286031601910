import React, {useContext} from "react";
import Navbar from "../componets/Navbar";
import Measurments from "../componets/measurments/Measurments";
import PlantProfile from "../componets/plants/PlantProfile";
import {AuthContext} from "../context/AuthContext";
import {useParams} from "react-router-dom";
import Spinner from "../componets/spinner";
import styles from "../styles/Common.module.css"
import {getMeasurements, getPlantData} from "../service/PlantsService";
import {Footer} from "antd/es/layout/layout";
import FooterFyta from "../componets/FooterFyta";
import { useQuery, useIsFetching } from "@tanstack/react-query";
import { TimelineContext } from "../context/TimelineContextProvider";
import { Collapse }  from "antd"
import PlantInfo from "../componets/plants/PlantInfo";

export default function PlantDetails() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const isFetching = useIsFetching();
  const {timeline} = useContext(TimelineContext);

  const {isError: plantDataIsError, data: plantData} = useQuery({
    queryKey: ['userPlantData', user.access_token, id],
    queryFn: () => getPlantData(id as string, user.access_token),
});

  const {isError: measureIsError, data: measureData} = useQuery({
    queryKey: ['plantMeasurement', user.access_token, id, timeline],
    queryFn: () => getMeasurements(id as string, user.access_token, timeline),
  });

  if (measureIsError || plantDataIsError) { 
    return (
      <div>
        <h3>Error loading data</h3>
      </div>
    );
  }

  return (
    <>
      <Navbar />

      {!!isFetching && <Spinner />}
      
      {!!plantData && !!measureData && <div className={styles.container}>
          <div className={styles.contentFull}>

            <PlantProfile plant={plantData} />

            <div className={styles.containerPlant}>
              
              <div className={`${styles.contentFullMeasurements} ${styles.contentPlant}`}>
                <Measurments measurments={measureData.measurements} dli_light={measureData.dli_light} thresholds={measureData.thresholds} plantData={plantData}/>
              </div>
              
              <div className={styles.contentPlant}>
                <Collapse
                  className={styles.contentPlantInfo}
                  defaultActiveKey={['1']}
                  expandIconPosition='end'
                  bordered={false}
                  items={[
                    {
                      key: '1',
                      label: <h2>Plant Information</h2>,
                      children: <PlantInfo plant={plantData} />
                    }
                  ]}    
                />
              </div>
              
                
            </div>

          </div>
      </div>}

      <Footer className={styles.footer}>
          <FooterFyta />
      </Footer>
    </>
  );
}
