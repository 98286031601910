import React from 'react';
import styles from "../../styles/plants/Plants.module.css";

export default function EmptyGarden() {

    return (
        <div className={styles.noPlantsContainer}>
            <img src='/EmptyGarden.png'></img>
            <p>No plants have been added to this garden yet. </p>
            <p>Go to the FYTA App and add a plant to this green space.</p>
        </div>
    )
}