import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import './App.css';
import Login from './componets/Login';
import Home from './pages/Home';
import PlantDetails from './pages/PlantDetails';
import { AuthContext } from './context/AuthContext';
import { useContext } from "react";
import ForgotPassword from './pages/ForgotPaswword';
import TandC from './componets/TandC';
import Pricing from './pages/Pricing'; 
import APIToken from './pages/APIToken';
function App() {
  const { user } = useContext(AuthContext);

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#126D34",
          },
          components: {
            Tabs: {
              inkBarColor: "#014C3A",
              itemColor: "#3F3F3F",
              itemActiveColor: "#3F3F3F",
              itemSelectedColor: "#3F3F3F",
              itemHoverColor: "#3F3F3F",
            },
            Collapse: {
              contentBg: "#F6F7F9",
              headerBg: "#F6F7F9",
            },
            Button: {
              defaultShadow: "0 0 0 0",
              primaryShadow: "0 0 0 0",
              paddingBlock: 20,
              defaultBg: "#F6F7F9",
              colorPrimary: "#126D34",
              colorPrimaryHover: "#126D34",
              colorPrimaryActive: "#126D34",
              fontWeight: 500,
              fontSize: 15,
            }
          }
        }}>
        <BrowserRouter>
            <Routes>
              <Route element={user == null ? <Login /> : <Navigate to="/" />} path={"/login"} />
              <Route element={user == null ? <ForgotPassword /> : <Navigate to="/" />} path={"/forgotpassword"} />
              <Route element={user != null ? <Home /> : <Navigate to="/login" />} path={"/"} />
              <Route element={user != null ? <PlantDetails /> : <Navigate to="/login" />} path={"/plants/:id"} />
              <Route element={user != null ? <Pricing user={user} /> : <Navigate to="/login" />} path={"/pricing"} />
              <Route element={user != null ? <APIToken /> : <Navigate to="/login" />} path={"/api-token"} />
            </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default App;
