import { ResponseUser } from "../types/ResponseUser";
import { Layout, Typography, Space } from "antd";
import styles from "../styles/Common.module.css";
import Navbar from "../componets/Navbar";
import FooterFyta from "../componets/FooterFyta";
import { useNavigate } from "react-router-dom";
import PricingPlans from "../componets/PricingPlans";

const { Header, Footer, Content } = Layout;
const { Title, Text } = Typography;

export default function Pricing(props: { user: ResponseUser }) {
    const navigate = useNavigate();

    return (
        <Layout className={styles.layout}>
            <Navbar />
            <Content className={styles.content}>
                <div className={styles.alignedContainer}>
                    <a href="#" className={styles.backLink} onClick={(e) => { e.preventDefault(); navigate(-1); }}>← Back</a>
                    <h2 className={styles.title}>Choose your Plan</h2>
                    <Title level={3} className={styles.subTitle}>Pricing plans</Title>
                </div>
                <PricingPlans/>
                <div className={styles.content}>
                <Text className={styles.cancelText}>
                    <center>Cancel or upgrade your plan anytime</center>
                </Text>
                </div>
            </Content>
            <Footer className={styles.footer}>
                <FooterFyta />
            </Footer>
        </Layout>
    );
}
