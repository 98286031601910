import moment from 'moment';
import MoistureChartSmall from "./MoistureChartSmall";
import FertilityChartSmall from "./FertilityChartSmall";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from "chart.js";
import TemperatureChartSmall from "./TemperatureChartSmall";
import LightChartSmall from "./LightChartSmall";
import { useContext } from 'react';
import { TimelineContext } from '../../context/TimelineContextProvider';
import { AuthContext } from '../../context/AuthContext';
import { ResponseMeasurements } from '../../types/ResponseMeasurements';
import { Measurement } from '../../types/Measurement';
import { useQueryClient } from '@tanstack/react-query';
import { Plant } from '../../types/Plant';
import styles from "../../styles/plants/Charts.module.css";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface MeasurementsSensorsProps {
    plant: Plant;
}

export default function MeasurementsSensors(props: MeasurementsSensorsProps) {
    
    let labels = [];
    let measurements: Measurement[] = [];

    const {user} = useContext(AuthContext);
    const {timeline} = useContext(TimelineContext)
    const queryClient = useQueryClient();

    const measurementsData = queryClient.getQueryData<ResponseMeasurements>(['plantMeasurement', user.access_token, props.plant.id, timeline]);

    if (measurementsData?.measurements) {
        measurements = measurementsData?.measurements.reverse();
    }

    const currentDate = moment();
    if (timeline === 'week') {
        labels = Array.from({ length: 8 }, (_, i) =>
            currentDate.clone().subtract(i, 'days').format('MMM D YYYY')).reverse();
    } else { // month
        labels = Array.from({ length: 31 }, (_, i) =>
            currentDate.clone().subtract(i, 'days').format('MMM D YYYY')).reverse();
    }

    return (
        <>
            {!!measurementsData && <div className={styles.smallChartsContainer}>
                <MoistureChartSmall
                    date={labels}
                    measurments={measurements}
                    thresholds={measurementsData.thresholds}
                    plant={props.plant}
                    key={'MoistureChartSmall' + props.plant.id}
                />

                <LightChartSmall
                    date={labels}
                    measurments={measurementsData.dli_light}
                    thresholds={measurementsData.thresholds}
                    plant={props.plant}
                    key={'LightChartSmall' + props.plant.id}
                />  

                <TemperatureChartSmall
                    date={labels}
                    measurments={measurements}
                    thresholds={measurementsData.thresholds}
                    plant={props.plant}
                    key={'TemperatureChartSmall' + props.plant.id}
                />

                <FertilityChartSmall
                    date={labels}
                    measurments={measurements}
                    thresholds={measurementsData.thresholds}
                    plant={props.plant}
                    key={'FertilityChartSmall' + props.plant.id}
                />         
            </div>}
        </>
    )
}
