import React, {useContext, useEffect, useState} from "react";
import styles from "../../styles/plants/Plants.module.css";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {AuthContext} from "../../context/AuthContext";
import MeasurementsSensors from "../measurments/MeasurementsSensors";
import { TimelineContext } from "../../context/TimelineContextProvider";
import { Plant } from "../../types/Plant";
import { getMeasurements } from "../../service/PlantsService";  
import moment from "moment";
import { Skeleton } from "antd";

interface PlantDataProps {
  plant: Plant,
}

export default function PlantData(props: PlantDataProps) {

  const {user} = useContext(AuthContext);
  const {timeline} = useContext(TimelineContext)
  const [ lastMeasurement, setLastMeasurement ] = useState("N/A");

  const { data: measurementsData, isFetching } = useQuery({
    queryKey: ['plantMeasurement', user.access_token, props.plant.id, timeline],
    queryFn: () => getMeasurements(props.plant.id.toString(), user.access_token, timeline),
  })

  useEffect(() => {
    if(!!measurementsData && measurementsData?.measurements.length !== 0) {
      const recentMeasurement = measurementsData?.measurements.reduce((prev, current) => {
        return moment.utc(prev.date_utc).isAfter(moment.utc(current.date_utc)) ? prev : current
      })
      const date = moment.utc(recentMeasurement.date_utc).local().format('DD. MMM, HH:mm')
      setLastMeasurement(date);
    } 
  }, [measurementsData])
    
    return (
        <>
          <Skeleton 
            loading={isFetching} 
            active={true}
            avatar={{
              shape: 'circle',
              size: 50,
            }}
            paragraph = {{
              rows: 5,
            }}
            style={{
              marginBottom: '10px'
            }}
          />
          {!isFetching && <Link to={`/plants/${props.plant.id}`}>
            <div className={styles.card}>
              <div className={styles.details}>
                <span className={styles.avatar}>
                  {props.plant.htmlImageSrc ? (
                    <img 
                      src={props.plant.htmlImageSrc} 
                      alt={props.plant.common_name}/>
                  ) : (
                    <img 
                      src="/image-placeholder.png"
                      alt="Placeholder"/>
                  )}
                </span>
                <h3>{props.plant.nickname}</h3>
                <div className={styles.cardSensorContainer}>
                  {!!props.plant.sensor && <img
                      src="/icons/ico-fyta-beam.svg"
                      alt="FYTA Beam Active"
                      width="23px"
                      height="23px"
                  /> }

                  {!props.plant.sensor && <img
                      src="/icons/ico-no-beam.svg"
                      alt="FYTA Beam Active"
                      width="23px"
                      height="23px"
                  /> }

                  {!!props.plant.hub && <img
                      src="/icons/ico-fyta-hub.svg"
                      alt="FYTA Beam Active"
                      width="23px"
                      height="23px"
                  /> }
                  </div>
                  <span>Last data collected:</span>
                  <span>{lastMeasurement}</span>
              </div>
              <MeasurementsSensors plant={props.plant} />
            </div>
          </Link>}
        </>
        
    )
    
}

