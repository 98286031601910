import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { googleLogout } from "@react-oauth/google";
import { useQueryClient } from "@tanstack/react-query";

export const useLogout = () => {
  const { dispatch } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const logout = () => {
    localStorage.removeItem("user");
    googleLogout();
    dispatch({ type: "LOGOUT" });
    queryClient.removeQueries();
  };

  return { logout };
};
