import styles from "../styles/Common.module.css"
import Navbar from "../componets/Navbar";
import Plant from "../componets/plants/Plant";
import Layout from "antd/lib/layout/layout";
import React, {useContext} from "react";
import Spinner from "../componets/spinner";
import {Footer, Header} from "antd/es/layout/layout";
import FooterFyta from "../componets/FooterFyta";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../context/AuthContext";
import { getPlantsData } from "../service/PlantsService";
import { ResponsePlantList } from "../types/ResponsePlantList";
import { UserContext } from "../context/UserContext";

export default function Home() {

    const {user} = useContext(AuthContext);
    const {userDetails} = useContext(UserContext);
    
    const {isError, data: homeData, isFetching, error} = useQuery({
        queryKey: ['homeData', user.access_token],
        queryFn: () => getPlantsData(user.access_token),
    });

    if(isError) {
        return <div>Error fetching data: {error.message} </div>
    }

    return (
        <>
            <Layout className={styles.layout}>
                <Navbar/>

                <Header className={styles.header}>
                    {!!userDetails.profileData && <h2 className={styles.pageHeader}>Hello {userDetails?.profileData.username}</h2>}
                </Header>

                <div className={styles.container}>
                    <div className={styles.homeContent}>
                        {isFetching && <Spinner /> && <div className={styles.loadingSpacer}/>}
                        {!!homeData && <Plant homeData={homeData as ResponsePlantList}/>}
                    </div>
                </div>

                <Footer className={styles.footer}>
                    <FooterFyta />  
                </Footer>
            </Layout>
        </>
    );
}
