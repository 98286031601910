import React, { useContext, useEffect, useState } from "react";
import styles from "../../styles/plants/Plants.module.css";
import { Tabs, Modal, Space, DatePicker, ConfigProvider, Radio, Select, Button, TabsProps } from "antd";
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { UserContext } from '../../context/UserContext';
import { ResponsePlantList } from "../../types/ResponsePlantList";
import { handleExport } from "./dataExportHelper"
import { Garden } from "../../types/Garden";
import { Plant } from "../../types/Plant";
import { AuthContext } from "../../context/AuthContext";
import { createExportDateRange } from "../measurments/ChartHelper";

dayjs.extend(customParseFormat);

interface ExportDataProps {
  homeData: ResponsePlantList
}

interface PlantOption {
  label: string;
  value: number;
}

export default function ExportData(props: ExportDataProps) {
  const { RangePicker } = DatePicker;
  const { TabPane } = Tabs;

  const [formatLabel, setFormatLabel] = useState("Please select");
  const [timeLabel, setTimeLabel] = useState("Please select");
  const [locations, setLocations] = useState<number[]>([-1]);
  const [plants, setPlants] = useState<number[]>([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()] as [Dayjs, Dayjs] | null);
  const [timeSelectorOpen, setTimeSelectorOpen] = useState(false);
	const [exportDisabled, setExportDisabled] = useState(true);
  const [plantOptions, setPlantOptions] = useState<PlantOption[]>([]);

  const { userDetails } = useContext(UserContext);
  const { user } = useContext(AuthContext);

  const isNonPayingCustomer = userDetails?.role === "user";

  useEffect(() => {
		if (formatLabel !== "Please select" && timeLabel !== "Please select"
      && locations.length > 0 && plants.length > 0) {
			setExportDisabled(false);
		} else {
			setExportDisabled(true);
		}
	}, [formatLabel, timeLabel, locations, plants]);

  const formatOptions = [
    { label: 'CSV', value: 'csv' },
    { label: 'JSON', value: 'json' }
  ];

  const fixedPeriodOptions = [
    { label: 'Last 7 days', value: 'Last 7 days' },
    { label: 'Last 14 days', value: 'Last 14 days' },
    { label: 'Last month', value: 'Last month' }
  ];

  const locationOptions = [
    { label: 'All', value: -1 } // -1 is the value for all locations
  ].concat(props.homeData.gardens.map((garden: Garden) => {
    return { label: garden.name.toString(), value: garden.id }
  }));

  useEffect(() => {
    setPlantOptions([
      { label: 'All', value: -1 }] // -1 is the value for all plants
    .concat(props.homeData.plants.map((plant: Plant) => {
      return { label: plant.nickname, value: plant.id }
    })).filter((option) => {
      if (locations.includes(-1) || locations.length === 0) {
        return true;
      }
      return locations.includes(props.homeData.plants.find((plant: Plant) => plant.id === option.value)?.garden?.id || -1);
    }));
    setPlants([]);
  }, [locations]);

  const handleLocationChange = (value: number[]) => {
    if (value.includes(-1)) {
      setLocations([-1]); 
    } else {
      setLocations(value.filter((v) => v !== -1));
    }
  };

  const handlePlantChange = (value: number[]) => {
    if (value.includes(-1)) {
      setPlants([-1]);
    } else {
      setPlants(value.filter((v) => v !== -1));
    }
  };

  const handleFixedTimeSelection = ((timePeriod: string) => {
		setTimeLabel(timePeriod);
    const today = dayjs();
    if (timePeriod === 'Last 7 days') {
      const startDate = today.subtract(7, 'days')
      setDateRange([startDate, today]);
    } 
    else if (timePeriod === 'Last 14 days') {
      const startDate = today.subtract(14, 'days')
      setDateRange([startDate, today]);
    } 
    else if (timePeriod === 'Last month') {
      const startDate = today.subtract(30, 'days')
      setDateRange([startDate, today]);
    }
		setTimeSelectorOpen(false);
	});

  const handleCustomTimeSelection = ((timeRange?: [Dayjs, Dayjs] | null) => {
		
		if (timeRange) {
			if (!!timeRange[0] && !!timeRange[1]) {
				setDateRange([timeRange[0], timeRange[1]]);
				setTimeLabel(createExportDateRange(timeRange[0], timeRange[1]));
			}
			else { // timerange has null or undefined values
				setTimeLabel('Please select');
				setDateRange(null);
			}
		}
		else {
			setTimeLabel("Please select");
			setDateRange(null);
		}
	})

  const handleTimeSelectorOpen = () => {
		if (!timeSelectorOpen){
			setTimeSelectorOpen(true);
		}
	}

  const timeTabItems: TabsProps['items'] = [
		{
			key: '1',
			label: <span className={styles.tabLabel}>Fixed period</span>,
			children:
				<>
					<Radio.Group
						onChange={(e) => handleFixedTimeSelection(e.target.value)}
						value={timeLabel}
						className={styles.verticalRadioGroup}
					>
						{fixedPeriodOptions.map((option, index) => (
							<React.Fragment key={option.value}>
							<div
								className={`${styles.radioOption} ${timeLabel === option.value ? styles.selected : ''}`}
							>
								<span className={styles.radioText}>{option.label}</span>
								<Radio value={option.value} className={styles.radioBtn} />
							</div>
							{index < fixedPeriodOptions.length - 1 && (
								<div className={styles.separator}></div>
							)}
							</React.Fragment>
						))}
					</Radio.Group>
				</>
		},
		{
			key: '2',
			label: <span className={styles.tabLabel}>Date range</span>,
			children:
				<>
					<RangePicker
						maxDate={dayjs()}
						defaultValue={dateRange!}
						onChange={(value) => {
							handleCustomTimeSelection([value?.[0]!, value?.[1]!])
							console.log(value);
						}}
						format={'YYYY/MM/DD'}
						needConfirm={true}
						placement="topRight"
						style={{ width: '80%', margin: '20px 10% 0 10%' }}
					/>
					<div 
						className={styles.doneButtonContainer} 
						onClick={() => {
							setTimeSelectorOpen(false)
							handleCustomTimeSelection(dateRange);
					}}>
						<p className={styles.doneButton}>Done</p>
					</div>
				</>
		}
	]

  const customTagRender = (props: any) => {
    const { label, closable, onClose } = props;
    return (
      <div className={styles.selectedOption}>
        {label}
        {closable && <span onClick={onClose} style={{ marginLeft: '8px' }}>x</span>}
      </div>
    );
  };

  const renderOption = (option: any, isSelected: boolean, isDisabled: boolean) => {
    return (
      <div className={`${styles.optionContainer} ${isDisabled ? styles.disabledOption : ''}`}>
        <span>{option.label}</span>
      </div>
    );
  };

  return (
    <>
    <ConfigProvider
        theme={{
          components: {
            Tabs: {
              inkBarColor: '#126D34',
              cardBg: '#F9FAF4',
            },
            DatePicker: {
              cellActiveWithRangeBg: '#A0F6AE',
            },
            Button: {
              paddingInline: 40,
            }
          },
        }}
      >
      <Button
        onClick={() => setModal2Open(true)}
        type="primary"
        disabled={isNonPayingCustomer}
      >
        <Space>
          <b>Export</b>
        </Space>
      </Button>
      
      
        <Modal
          className={styles.exportOptions}
					title={<span><b>Export Options</b></span>}
					centered
					open={modal2Open}
					onCancel={() => {setModal2Open(false)}}
					afterClose={() => {setTimeSelectorOpen(false)}}
					destroyOnClose={true}
					footer={null}
        >
          <div className={styles.separator}></div>
          <div className={styles.optionRow}>
            <div className={styles.rowItem}>
              <p><b className={styles.rowSpacing}>Format</b></p>
              <Select
                className={`${styles.fieldPadding} ${styles.timePadding}`}
                value={formatLabel}
                onChange={(value) => setFormatLabel(value)}
                popupClassName={styles.customDropdown}
							>
								{formatOptions.map((option, index) => (
									<React.Fragment key={option.value}>
										<Select.Option value={option.value}>{option.label}</Select.Option>
									</React.Fragment>
								))}
							</Select>
            </div>
            <div className={styles.rowItem}>
              <p><b className={styles.rowSpacing}>Time</b></p>
              <Select
								className={styles.timePadding}
								onClick={() => handleTimeSelectorOpen()}
								open={timeSelectorOpen}
								value={timeLabel}
								dropdownRender={(menu) => (
									<div className={styles.tabs}>
										<Tabs defaultActiveKey="1" items={timeTabItems}/>
									</div>
								)}
								dropdownStyle={{
									width: '328px', 
									height: '187px', 
									border: 'none',  
									boxShadow: 'none',  
									padding: '0px',  
								}}
							/>
            </div>
          </div>
          <div>
            <p><b>Location</b></p>
            <Select
              className={styles.plantSelect}
              showSearch={false}
              mode="multiple"
              value={locations}
              onChange={handleLocationChange}
              placeholder="Please select"
              tagRender={customTagRender}
              style={{ width: '100%' }}
              dropdownStyle={{
                borderRadius: '20px',
                backgroundColor: '#F9FAF4',
                width: '450px',
                overflowX: 'hidden',
                border: 'none',
                boxShadow: 'none',
                padding: '0px',
              }}
            >
              {locationOptions.map((option) => (
                <Select.Option key={option.value} value={option.value} disabled={locations.includes(-1) && option.value !== -1}>
                  {renderOption(option, locations.includes(option.value), locations.includes(-1) && option.value !== -1)}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <p className={styles.plantText}><b>Plants</b></p>
            <Select
              className={styles.plantSelect}
              showSearch={false}
              mode="multiple"
              value={plants}
              onChange={handlePlantChange}
              placeholder="Please select"
              tagRender={customTagRender}
              style={{ width: '100%' }}
              dropdownStyle={{
                borderRadius: '20px',
                backgroundColor: '#F9FAF4',
                width: '450px',
                overflowX: 'hidden',
                border: 'none',
                boxShadow: 'none',
                padding: '0px',
              }}
            >
              {plantOptions.map((option) => (
                <Select.Option key={option.value} value={option.value} disabled={plants.includes(-1) && option.value !== -1}>
                  {renderOption(option, plants.includes(option.value), plants.includes(-1) && option.value !== -1)}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Button
            className={`${styles.plantExport} ${!exportDisabled ? styles.enabled : ""}`}
            disabled={exportDisabled}
            onClick={() => {
              if (plants.includes(-1)) {
                const allPlants = props.homeData.plants.map((plant) => plant.id);
                handleExport(user.access_token, formatLabel, dateRange!, allPlants)
              }
              else {
                handleExport(user.access_token, formatLabel, dateRange!, plants)
              }
            }}
          >
            Export
          </Button>
        </Modal>
      </ConfigProvider>
    </>
  );
}
