import axios from "axios";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
export const useLogin = () => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dispatch } = useContext(AuthContext);
  const constructRedirectUrl = (
    iftttRequest: {
      redirect_uri: string;
      state: string;
    },
    responseData: {
      code: string;
    }
  ) => {
    return `${iftttRequest.redirect_uri}?state=${iftttRequest.state}&code=${responseData.code}`;
  };
  const login = async (formData: any, iftttMode = false) => {
    setIsLoading(true);
    setError('');
    const data = {
      ...formData,
    };
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASEURL}/auth/login`,
        method: "post",
        data,
      });

      // for ifttt mode redirect to the url provided by the backend
      if (iftttMode) {
        return window.location.replace(
          constructRedirectUrl(formData, response.data)
        );
      }
      // window.localStorage.setItem('user', JSON.stringify(response.data))

      dispatch({ type: "LOGIN", payload: response.data });

      // window.localStorage.setItem('userDetails', JSON.stringify(formData))
      setIsLoading(false);
      return;
    } catch (error: any) {
      setIsLoading(false);
      if (error.code === "ERR_BAD_REQUEST") {
        setError("Login Failed. Please check your email and password and try again.");
      } else {
        setError(error.message);
      }
    }
  };

  const mirrorLogin = async (formData: any, iftttMode = false, userID: number) => {
    setIsLoading(true);
    setError('');
    const data = {
      ...formData,
      assume_user_id: userID,
    };
    try {

      const response = await axios({
        url: `${process.env.REACT_APP_BASEURL}/auth/mirror-login`,
        method: "post",
        data,
      });

      // for ifttt mode redirect to the url provided by the backend
      if (iftttMode) {
        return window.location.replace(
          constructRedirectUrl(formData, response.data)
        );
      }

      dispatch({ type: "LOGIN", payload: response.data });

      setIsLoading(false);
      return;
    } catch (error: any) {
      setIsLoading(false);
      if (error.code === "ERR_BAD_REQUEST") {
        setError("Login Failed. Please check your email and password and try again.");
      } else {
        setError(error.message);
      }
    }
  };

  const googleLogin = async (credentialResponse: any, iftttMode = false) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/auth/login/google?lang=en`,
        {
          googleIdToken: credentialResponse.credential,
          ifttt_client_id: credentialResponse.ifttt_client_id,
        }
      );

      // for ifttt mode redirect to the url provided by the backend
      if (iftttMode) {
        return window.location.replace(
          constructRedirectUrl(credentialResponse, response.data)
        );
      }
      dispatch({ type: "LOGIN", payload: response.data });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  return { login, googleLogin, mirrorLogin, isLoading, error };
};
