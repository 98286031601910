import React from 'react';
import styles from "../../styles/plants/Plants.module.css";

export default function NoPlants() {

    return (
        <div className={styles.noPlantsContainer}>
            <img src='/EmptyGarden.png'></img>
            <p>It looks like you haven't added any plants yet.</p>
            <p>Start by adding your first plant in our FYTA App.</p>
        </div>
    )
}