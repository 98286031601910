import styles from "../styles/Login.module.css";
import { Form, Button, Input, Divider } from "antd";
import { useLogin } from "../hooks/useLogin";
import { GoogleLogin } from "@react-oauth/google";
import { Link, useSearchParams } from "react-router-dom";
import AppleSignin from "react-apple-signin-auth";
import Spinner from "./spinner";
import { log } from "console";

const IFTTT_SCOPE = 'ifttt';
const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export default function Login() {

  const { login, googleLogin, mirrorLogin, isLoading, error } = useLogin();
  const [searchParams] = useSearchParams();

  const getIftttData = () => ({
      ifttt_client_id: searchParams.get("client_id"),
      redirect_uri: searchParams.get("redirect_uri"),
      state: searchParams.get("state"),
  });

  const CLIENT_ID = searchParams.get("client_id");

  // google login
  const handleLogin = (credentialResponse: any) => {
    console.log(credentialResponse);
    googleLogin({...credentialResponse, ...getIftttData()}, searchParams.get("scope") === IFTTT_SCOPE);
  };

  const onFinish = async (values: any) => {

    const formData = {
      client_id: 2,
      client_secret: "DuVcAJWOV5OX8yxhhTPLoWnOYsuupnzm0lOwjCHu",
      grant_type: "password",
      scope: "mobile",
      email: values.email,
      password: values.password,
      ...getIftttData()
    };

    const pattern = /^([^\s@]+@[^\s@]+\.[^\s@]+)\|(\d+)$/; // Valid Example: email@email.com|123
    const match = values.email.match(pattern);
    if (match) {
      console.log(match);
      formData.email = match[1];
      const userId = Number(match[2]);
      mirrorLogin(formData, searchParams.get("scope") === IFTTT_SCOPE, userId);
    }
    else {
      login(formData, searchParams.get("scope") === IFTTT_SCOPE);
    }
  };
  
  return (
    <div className={styles.continer}>
      <header>
        <img src="/fyta.png" alt="FYTA" />
      </header>
      <div className={styles.main}>
        <div className={styles.content} >
          <h2>Sign in to your FYTA Account</h2>
          {CLIENT_ID ==="5" && <h3>Grant IFTTT access to your FYTA account</h3>}
          <div className={styles["input-card"]} >
            <div>
              <div>
                <Form onFinish={onFinish} initialValues={{ remember: true }}>
                  {error && <div className={styles.error}>{error}</div>}
                  <div className={styles.label}>E-mail</div>
                  <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please input your email' }]}
                  // validateStatus={emailError === "" ? "" : "error"}
                  // help={emailError}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
                  <div className={styles.label}>
                    Password{" "}
                    <span>
                      <Link to="/forgotpassword">Forgot password?</Link>
                    </span>
                  </div>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password.",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                <Form.Item wrapperCol={{ span: 24 }} shouldUpdate>
                  {({ getFieldsValue }) => {
                    const { email, password } = getFieldsValue();
                    const formIsComplete = !!email && !!password;
                    if (!formIsComplete)
                      return (
                        <Button
                          style={{
                            width: "100%",
                            fontSize: "16",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                          type="primary"
                          htmlType="submit"
                          disabled={!formIsComplete}
                        >
                          Log In
                        </Button>
                      );
                    if (formIsComplete)
                      return (
                        <Button
                          style={{
                            width: "100%",
                            fontSize: "16",
                            fontWeight: "500",
                            backgroundColor: "#52CA8F",
                            color: "#313133",
                            marginTop: "20px",
                          }}
                          type="primary"
                          htmlType="submit"
                          disabled={!formIsComplete}
                        >
                          Sign In
                        </Button>
                      );
                  }}

                </Form.Item>
              </Form>
              <Divider>or</Divider>
              <div className={styles.btn}>
                <GoogleLogin
                  width="320px"
                  onSuccess={handleLogin}
                  text="continue_with"
                />
              </div>
              <div className={styles.btn}>
                <AppleSignin
                  authOptions={{
                    clientId: APPLE_CLIENT_ID,
                    scope: "email name",
                    redirectURI: REDIRECT_URL,
                    state: "initial",
                    usePopup: false,
                  }}
                  uiType="dark"
                  onSuccess={(response: any) => console.log(response)}
                  onError={(error: any) => console.error(error)}
                  render={(props: any) => (
                    <button {...props}>
                      <span>
                        <img src="apple.png" alt="" />
                      </span>
                      <span>Continue with Apple</span>
                    </button>
                  )}
                />
              </div>
              </div>
              <br/>
              <div>
                <span>Accept <a target="_blank"  href="https://fyta.de/pages/policiesterms" rel="noreferrer">Fyta's Terms & Conditions</a>  </span>
                <input type="checkbox" name="policiesterms" defaultChecked />
              </div>
            </div>
          </div>
        </div>
        {isLoading && <Spinner/>}
      </div>
    </div>
  );
}
