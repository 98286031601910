import React, {useContext} from "react"
import styles from "../../styles/plants/Plantprofile.module.css"

export default function PlantProfile({plant}:any) {

  return (
    <div>
        <a href="#" className={styles.backLink} onClick={(e) => { e.preventDefault(); window.history.back(); }}>← Back</a>
        <div className={styles.header}>
          <div className={styles.avatar}>
            {plant.htmlImageSrc ? (
                <img src={plant.htmlImageSrc} alt={plant.nickname}/>
            ) : (
                <img src="/image-placeholder.png" alt={'placeholder grey circle'}/>
            )}
          </div>
            <div>
                <h1>
                  {!!plant.nickname ? plant.nickname: "--"}
                </h1>
                <div className={styles.status}>
                    {!!plant.sensor && <img
                        src="/icons/ico-fyta-beam.svg"
                        alt="FYTA Beam Active"
                    /> }

                    {!plant.sensor && <img
                        src="/icons/ico-no-beam.svg"
                        alt="FYTA Beam Inactive"
                    /> }

                    { !!plant.hub && <img
                        src="/icons/ico-fyta-hub.svg"
                        alt="FYTA Hub Active"
                    /> }
                </div>
            </div>
        </div>
    </div>
  )
}
