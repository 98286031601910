import {createContext, useState} from "react";

export const TimelineContext = createContext();

export default function TimelineContextProvider({children}) {
    const [timeline, setTimeline] = useState('week');
    return (
        <TimelineContext.Provider value={{timeline, setTimeline}}>
            {children}
        </TimelineContext.Provider>
    );
}
