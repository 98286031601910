import {Chart} from "react-chartjs-2";
import React, { useRef } from 'react'
import styles from "../../styles/plants/Charts.module.css";
import moment from "moment";
import {Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend, TimeScale, Filler} from "chart.js"; 
import 'chartjs-adapter-moment';
import { createHeaderDateRange, displayFormat, stepSize } from "./ChartHelper";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, TimeScale, Filler); 

export default function LightChartSmall({date, measurments, thresholds, plant}) {
    const light = measurments.map(each => ({
        x: moment.utc(each.date_utc).format('YYYY-MM-DD'),
        y: each.dli_light,
    }))
    const hasData = light.length > 0;
    const chartRef = useRef(null);  

    const headerDateRange = createHeaderDateRange(date);

    const data = {
        labels: date,
        datasets: [
            {
                label: 'DLI',
                data: light,
                backgroundColor: '#454744',
                borderRadius: 20,   
                barThickness: 5,
            }
        ],
    };

    const options = {
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    display: false
                },
                position: 'right',
                min: 0,
                max: 50,
                ticks: {
                    align: 'start',
                    stepSize: 10,
                },
            },
            x: {
                grid: {
                    borderDash: [8, 4],
                    drawBorder: false,
                },
                type: 'time',
                time: {
                    displayFormats: {
                        day: displayFormat(date),
                    },
                    max: date[date.length - 1],
                    min: date[0],
                    stepSize: stepSize(date),
                },
                title: {
                    display: false,
                    text: 'Time'
                },
                ticks: {
                    font: {
                        weight: 'bold',
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                  title: function() {
                    return '';
                  },
                  label: function(context) {
                    const data = context.raw;
                    return data.y + ' mol/day';
                  },
                  afterBody: function(context) {
                    const date = context[0].raw.x;
                    return 'Date taken: ' + moment(date).format('MMM D');
                  }
                }
              }
        },
    }

    return (
        <div className={styles.chartSmall}>
            {!hasData && <div className={styles.chartNoData}>No Light data</div>}
            {!!hasData && 
            <>
                <div className={styles.chartSmallHeaderContainer}>
                    <h3>
                        {thresholds.dli_light_min_good} - {thresholds.dli_light_max_good}
                    </h3>
                    <p>
                        {headerDateRange}
                    </p>
                </div>
                <p>Optimal Range</p>
                <Chart ref={chartRef} type='bar' data={data} options={options} key={'BarLightChartSmall' + plant.id}/>
            </>}
        </div>
    )
}
