import React, { useContext } from 'react';
import { Plant } from '../../types/Plant';
import styles from '../../styles/plants/Plantprofile.module.css';
import { useQuery } from '@tanstack/react-query';
import { AuthContext } from '../../context/AuthContext';
import { getPlantsData, getStartupData } from '../../service/PlantsService';

interface PlantInfoProps {
    plant: Plant;
}

const PlantInfo: React.FC<PlantInfoProps> = ({plant}) => {

    const { user } = useContext(AuthContext);
    
    const {isError: homeDataIsError, data: homeData} = useQuery({
        queryKey: ['homeData', user.access_token],
        queryFn: () => getPlantsData(user.access_token),
    });

    const {isError: startupDataError, data: startupData} = useQuery({
        queryKey: ['startupData', user.access_token],
        queryFn: () => getStartupData(user.access_token),
    });

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function getLocationText(location: string) {
        if (location === 'indoor') {
            return 'Indoor'
        }
        else if (location === 'outdoor') {
            return 'Outdoor'
        }
        else if (location === 'grow_tent') {
            return 'Grow Tent'
        }
        else {
            return 'N/A'
        }
    }

    function getSeedTypeText(seedType: string) {
        if (seedType === 'ornamental') {
            return 'Ornamental'
        }
        else if (seedType === 'day_neutral') {
            return 'Autoflowering'
        }
        else if (seedType === 'short_day') {
            return 'Photoperiodic'
        }
        else if (seedType === 'long_day') {
            return 'Photoperiodic Fast Version'
        }
        else {
            return 'N/A'
        }

    }

    function getSensorProbeLength(probeLengthId: number) {
        return startupData?.sensor_probe_lengths.find((probeLength: any) => probeLength.id === probeLengthId)?.text || 'N/A';
    }

    return (
        <div className={styles.plantInfoContainer}>
            <div className={styles.plantInfoColumn}>
                <div>
                    <span>Species</span>
                    <h3>{plant?.scientific_name || 'Name of Species'}</h3>
                </div>
                <div>
                    <span>Soil pH Level</span>
                    <h3>{plant?.measurements["ph"]["values"]?.["current"] || 'Not Set'}</h3>
                </div>
                <div>
                    <span>Pot Size</span>
                    <h3>{plant?.pot_size || 'Not Set'}</h3>
                </div>
                <div>
                    <span>Light Calibration</span>
                    <h3>{plant?.sensor?.light_factor === -1 ? 'Recalibrate' : plant?.sensor?.light_factor.toPrecision(3) || 'Remaining'}</h3>
                </div>
                <div>
                    <span>Beam Legs</span>
                    <h3>{!!plant?.sensor?.probe_length_id ? getSensorProbeLength(plant.sensor.probe_length_id) : 'Not Set'}</h3>
                </div>
                {!!plant?.location && <div>
                    <span>Location</span>
                    <h3>{!!plant.location ? getLocationText(plant.location) : 'Not Set'}</h3>
                </div>}
            </div>
            <div className={styles.plantInfoColumn}>
                <div>
                    <span>Garden</span>
                    <h3>{homeData?.gardens[plant.garden?.id]?.name || 'Not Set'}</h3>
                </div>
                <div>
                    <span>Soil Type</span>
                    <h3>{startupData?.plant_meta.soil_types.find((soilType: any) => soilType.id === plant.soil_type_id)?.label || 'Not Set'}</h3>
                </div>
                <div>
                    <span>Drainage</span>
                    <h3>{plant.drainage?.valueOf() ? 'Yes' : 'Not Set' || 'Not Set'}</h3>
                </div>
                <div>
                    <span>Soil Calibration</span>
                    <h3>{plant?.sensor?.moisture_factor?.toPrecision(3) || 'Remaining'}</h3>
                </div>
                {!!plant?.grow_stage && <div>
                    <span>Grow Stage</span>
                    <h3>{capitalizeFirstLetter(plant?.grow_stage) || 'Not Set'}</h3>
                </div>}
                {!!plant?.seed_type && <div>
                    <span>Seed Type</span>
                    <h3>{!!plant.seed_type ? getSeedTypeText(plant.seed_type) : 'Not Set'}</h3>
                </div>}

            </div>
        </div>
    )
}

export default PlantInfo;