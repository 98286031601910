import { Typography, Space } from "antd";
import styles from "../styles/Common.module.css";

const { Text } = Typography;

export default function PricingPlans() {

    return (
        <>
            <div className={styles.pricingContent}>
                <Space direction="vertical" size={16} className={styles.space}>
                    <Text className={styles.text}>
                        Get FYTA Pro to access our exclusive features that provide you with even greater control over your plants' data. Get to know about the highlights of what you can expect as a FYTA Pro user.
                    </Text>
                    <Text className={styles.text}>
                        The FYTA Beam is a product that is so flexible and sophisticated that we can support many applications in science and industry. If you want to know more about the FYTA System <a href="https://ifttt.com/fyta" target="_blank" rel="noopener noreferrer">click here</a>.
                    </Text>
                </Space>
                <div className={styles.pricingBoxes}>
                    <div className={`${styles.pricingBox} ${styles.freeBox}`}>
                        <h3><b>FYTA Free</b></h3>
                        <p>For personal Use</p>
                        <div className={styles.separator}></div>
                        <ul>
                            <li className={styles.check}>Plants overview</li>
                            <li className={styles.check}>Token-based CI service access</li>
                            <li className={styles.check}>Plant Measurements</li>
                            <li className={styles.neutral}>Custom CSV and Excel exports</li>
                            <li className={styles.neutral}>Simultaneous downloads</li>
                            <li className={styles.neutral}>Unified plant measurements dashboard</li>
                        </ul>
                        <button className={`${styles.button} ${styles.freeButton}`}>Get Fyta Free</button>
                    </div>
                    <div className={styles.pricingBox}>
                        <h3><b>FYTA Pro</b></h3>
                        <p>For Pro, Educational and Industry Usecases</p>
                        <div className={styles.separator}></div>
                        <ul>
                            <li className={styles.check}>Plants overview</li>
                            <li className={styles.check}>Token-based CI service access</li>
                            <li className={styles.check}>Plant Measurements</li>
                            <li className={styles.check}>Custom CSV and Excel exports</li>
                            <li className={styles.check}>Simultaneous downloads</li>
                            <li className={styles.check}>Unified plant measurements dashboard</li>
                        </ul>
                        <button className={`${styles.button} ${styles.proButton}`} onClick={() => window.open('https://fyta.de/en/pages/contact-form', '_blank')}>Get FYTA Pro</button>
                    </div>
                </div>
            </div>
        </>
    )
}