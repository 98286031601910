import React from 'react';
import styles from '../styles/Avatar.module.css';
import { useLogout } from '../hooks/useLogout';
import { Divider } from 'antd';


export interface AvatarDropdownProps {
    username: string;
    email: string;
}

export function AvatarDropdown ({ username, email }: AvatarDropdownProps)  {

  const { logout } = useLogout();

  return (
    <div className={styles.dropdown}>
        <h3>{username}</h3>
        <span>{email}</span>
        <Divider className={styles.divider}/>
        <div className={styles.logout} onClick={() => logout()}>
          <span><img src="/icons/icon-logout.svg" alt="" /></span>
          <span>Logout</span>
        </div>
    </div>
  )
}
