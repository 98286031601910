import React, { useContext, useEffect, useState } from "react";
import styles from "../../styles/plants/Plants.module.css";
import { Tabs, Modal, Space, DatePicker, ConfigProvider, Radio, Select, Button, TabsProps } from "antd";
import dayjs, { Dayjs } from 'dayjs';
import { UploadOutlined } from "@ant-design/icons";
import { Plant } from "../../types/Plant";
import { createExportDateRange } from "../measurments/ChartHelper";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { handleExport } from "./dataExportHelper";
import { AuthContext } from "../../context/AuthContext";
import PricingPlans from "../PricingPlans";
import { UserContext } from "../../context/UserContext";
dayjs.extend(customParseFormat);

interface ExportPlantProps {
	plant: Plant;
}

export default function ExportPlant(props: ExportPlantProps) {
	const plant = props.plant;
	const dateFormat = 'YYYY/MM/DD';
	const { RangePicker } = DatePicker;

	const [formatLabel, setFormatLabel] = useState("Please select");
	const [timeLabel, setTimeLabel] = useState("Please select");
	const [modal2Open, setModal2Open] = useState(false);
	const [pricingModalOpen, setPricingModalOpen] = useState(false);
	const [dateRange, setDateRange] = useState([dayjs(), dayjs()] as [Dayjs, Dayjs] | null);
	const [exportDisabled, setExportDisabled] = useState(true);
	const [timeSelectorOpen, setTimeSelectorOpen] = useState(false);

	const { user } = useContext(AuthContext);
	const { userDetails } = useContext(UserContext)

	useEffect(() => {
		if (formatLabel !== "Please select" && timeLabel !== "Please select") {
			setExportDisabled(false);
		} else {
			setExportDisabled(true);
		}
	}, [formatLabel, timeLabel]);

	const formatOptions = [
		{ label: 'CSV', value: 'csv' },
		{ label: 'JSON', value: 'json' }
	];

	const fixedPeriodOptions = [
		{ label: 'Last 7 days', value: 'Last 7 days' },
		{ label: 'Last 14 days', value: 'Last 14 days' },
		{ label: 'Last month', value: 'Last month' }
	];

	const handleFixedTimeSelection = ((timePeriod: string) => {
		setTimeLabel(timePeriod);
    const today = dayjs();
    if (timePeriod === 'Last 7 days') {
      const startDate = today.subtract(7, 'days')
      setDateRange([startDate, today]);
    } 
    else if (timePeriod === 'Last 14 days') {
      const startDate = today.subtract(14, 'days')
      setDateRange([startDate, today]);
    } 
    else if (timePeriod === 'Last month') {
      const startDate = today.subtract(30, 'days')
      setDateRange([startDate, today]);
    }
		setTimeSelectorOpen(false);
	});

	const handleCustomTimeSelection = ((timeRange?: [Dayjs, Dayjs] | null) => {
		
		if (timeRange) {
			if (!!timeRange[0] && !!timeRange[1]) {
				setDateRange([timeRange[0], timeRange[1]]);
				setTimeLabel(createExportDateRange(timeRange[0], timeRange[1]));
			}
			else { // timerange has null or undefined values
				setTimeLabel('Please select');
				setDateRange(null);
			}
		}
		else {
			setTimeLabel("Please select");
			setDateRange(null);
		}
	})

	const handleTimeSelectorOpen = () => {
		if (!timeSelectorOpen){
			setTimeSelectorOpen(true);
		}
	}

	const handleExportOnClick = () => {
		
		if (userDetails?.role === "user") {
			setPricingModalOpen(true);
		} else {
			setModal2Open(true);
		}
	}

	const timeTabItems: TabsProps['items'] = [
		{
			key: '1',
			label: <span className={styles.tabLabel}>Fixed period</span>,
			children:
				<>
					<Radio.Group
						onChange={(e) => handleFixedTimeSelection(e.target.value)}
						value={timeLabel}
						className={styles.verticalRadioGroup}
					>
						{fixedPeriodOptions.map((option, index) => (
							<React.Fragment key={option.value}>
							<div
								className={`${styles.radioOption} ${timeLabel === option.value ? styles.selected : ''}`}
							>
								<span className={styles.radioText}>{option.label}</span>
								<Radio value={option.value} className={styles.radioBtn} />
							</div>
							{index < fixedPeriodOptions.length - 1 && (
								<div className={styles.separator}></div>
							)}
							</React.Fragment>
						))}
					</Radio.Group>
				</>
		},
		{
			key: '2',
			label: <span className={styles.tabLabel}>Date range</span>,
			children:
				<>
					<RangePicker
						maxDate={dayjs()}
						defaultValue={dateRange!}
						onChange={(value) => {
							handleCustomTimeSelection([value?.[0]!, value?.[1]!])
							console.log(value);
						}}
						format={dateFormat}
						needConfirm={true}
						placement="topRight"
						style={{ width: '80%', margin: '20px 10% 0 10%' }}
					/>
					<div 
						className={styles.doneButtonContainer} 
						onClick={() => {
							setTimeSelectorOpen(false)
							handleCustomTimeSelection(dateRange);
					}}>
						<p className={styles.doneButton}>Done</p>
					</div>
				</>
		}
	]

	return (
		<>
			<ConfigProvider
				theme={{
					components: {
						Tabs: {
							inkBarColor: '#126D34',
							cardBg: '#F9FAF4',
						},
						DatePicker: {
							cellActiveWithRangeBg: '#A0F6AE',
						},
						Button: {
							paddingInline: 40,
						}
					},
				}}
			>
				<Button 
					onClick={() => handleExportOnClick()}
					type="primary"
				>
					<Space>
						<UploadOutlined />
						<p>Export</p>
					</Space>
				</Button>
				<Modal
					className={styles.exportOptions}
					title={<span><b>Export Options</b></span>}
					centered
					open={modal2Open}
					onCancel={() => {setModal2Open(false)}}
					afterClose={() => {setTimeSelectorOpen(false)}}
					destroyOnClose={true}
					footer={null}
				>
					<div className={styles.separator}></div>
					<div className={styles.optionRow}>
						<div className={styles.rowItem}>
							<p><b className={styles.rowSpacing}>Format</b></p>
							<Select
								className={`${styles.fieldPadding} ${styles.timePadding}`}
								value={formatLabel}
								onChange={(value) => setFormatLabel(value)}
								popupClassName={styles.customDropdown}
							>
								{formatOptions.map((option, index) => (
									<React.Fragment key={option.value}>
										<Select.Option value={option.value}>{option.label}</Select.Option>
									</React.Fragment>
								))}
							</Select>
						</div>
						<div className={styles.rowItem}>
							<p><b className={styles.rowSpacing}>Time</b></p>
							<Select
								className={styles.timePadding}
								onClick={() => handleTimeSelectorOpen()}
								open={timeSelectorOpen}
								value={timeLabel}
								dropdownRender={(menu) => (
									<div className={styles.tabs}>
										<Tabs defaultActiveKey="1" items={timeTabItems}/>
									</div>
								)}
								dropdownStyle={{
									width: '328px', 
									height: '187px', 
									border: 'none',  
									boxShadow: 'none',  
									padding: '0px',  
								}}
							/>
						</div>
					</div>
					<Button
						className={`${styles.plantExport} ${!exportDisabled ? styles.enabled : ""}`}
						disabled={exportDisabled}
						onClick={() => handleExport(user.access_token, formatLabel, dateRange!, [plant.id])}
					>
						Export
					</Button>
				</Modal>
				<Modal
					title={<div style={{ textAlign: 'center', marginTop: '50px' }}><b>Update to FYTA Pro</b></div>}
					centered
					open={pricingModalOpen}
					onCancel={() => setPricingModalOpen(false)}
					footer={null}
					style={{height: '711px' }}
					width={730}
              	>
                	<PricingPlans/>
              	</Modal>
			</ConfigProvider>
		</>
	);
	}
