import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import styles from "../styles/Navbar.module.css"
import { CloseOutlined ,MenuOutlined  } from '@ant-design/icons';
import {FC, PropsWithChildren, useEffect, useRef, useState} from 'react';
import { UserContext } from '../context/UserContext';
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { ResponseUser } from "../types/ResponseUser";
import Avatar from './Avatar';
import { Drawer } from 'antd';


export default function Navbar() {
  const navRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false); 
  const { user } = useContext(AuthContext);
  const { userDetails, setUserDetails } = useContext(UserContext);    
  const [navbarOpen, setNavbarOpen] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: "Bearer " + user.access_token,
  };

  function getUserDetails() {
    return axios.get<ResponseUser>(`${process.env.REACT_APP_BASEURL}/user`, { headers });
  }

  useEffect(() => {
    getUserDetails().then(r => {
      setUserDetails(r.data);
    });
  }, [setUserDetails]);

  type NavbarLinkProps = PropsWithChildren<{
    to: string;
  }>;

  const CustomLink: FC<NavbarLinkProps> = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
      <Link to={to} {...props} className={isActive ? `${styles.navLink} ${styles.active}` : styles.navLink}>
        {children}
      </Link>
    );
  };

  const toggleNavBar = () => {
    // navRef.current.classList.toggle(`${styles.responsive_nav}`);
    setNavbarOpen(!navbarOpen);
  };

   const isNonPayingCustomer = userDetails?.role === "user";
   const isAdmin = userDetails?.role === "admin";
 

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.nav_btn} onClick={toggleNavBar}>
          <MenuOutlined />
        </div>
        <Drawer
          open={navbarOpen}
          onClose={toggleNavBar}
          width='100%'
          placement='left'
          title= {
            <div className={styles.container_small}>
              <Link to="/"><img src="/fyta.png" alt="FYTA" className={styles.navLogo} /></Link>
              <div ref={navRef} className={styles.iconbuttons}>
                <div className={styles.actions}>
                    {/* have pro buttion version appear for admin and nonpaying user */}
                    {(isNonPayingCustomer || isAdmin) && (
                      <Link to="/pricing" className={styles.getProButton}>Get FYTA Pro</Link>
                    )}
                </div>
                <div className={styles.grayRectangle}></div>
                <div>
                  <span>
                    <a href="https://fyta.de/en/pages/help-center" target="_blank" rel="noopener noreferrer">
                      <img src="/icons/help-svgrepo-com.svg" alt="" width="18" height="18" />
                    </a>
                  </span>
                </div>
                <Avatar username={userDetails?.profileData?.username || ''} email={userDetails?.profileData?.email || ''} />
              </div>
            </div>
          }
          styles={{
            body: {
              backgroundColor: '#d0dbbf',
            },
          }}
          >
            <div className={`${styles.responsive_navitems} ${styles.navitems}`}>
              <div>
                <CustomLink to="/" >My Plants</CustomLink>
                <CustomLink to="/api-token">API Token</CustomLink>
                <CustomLink to="/pricing">Pricing</CustomLink>
              </div>
            </div>
        </Drawer>
      </div>
      
      <Link to="/"><img src="/fyta.png" alt="FYTA" className={styles.navLogo} /></Link>
      <div className={`${styles.navitems} ${styles.hide_on_small_screen}`}>
        <div>
          <CustomLink to="/" >My Plants</CustomLink>
          <CustomLink to="/api-token">API Token</CustomLink>
          <CustomLink to="/pricing">Pricing</CustomLink>
        </div>
      </div>
      <div ref={navRef} className={styles.iconbuttons}>
        <div className={styles.actions}>
            {/* have pro buttion version appear for admin and nonpaying user */}
            {(isNonPayingCustomer || isAdmin) && (
              <Link to="/pricing" className={styles.getProButton}>Get FYTA Pro</Link>
            )}
        </div>
        <div className={styles.grayRectangle}></div>
        <div>
          <span>
            <a href="https://fyta.de/en/pages/help-center" target="_blank" rel="noopener noreferrer">
              <img src="/icons/help-svgrepo-com.svg" alt="" width="18" height="18" />
            </a>
          </span>
        </div>
        <Avatar username={userDetails?.profileData?.username || ''} email={userDetails?.profileData?.email || ''} />
      </div>
    </div>
  );
}
