import React, { useContext, useState } from "react";
import styles from "../../styles/measurments/Measurments.module.css";
import MoistureChart from './MoistureChart';
import FertilityChart from "./FertilityChart";
import LightChart from './LightChart';
import TempratureChart from './TempratureChart';
import pricing from "../../styles/Common.module.css";
import moment from 'moment';
import { Button, MenuProps, Dropdown, Space, ConfigProvider, Typography, Modal } from "antd";
import { TimelineContext } from "../../context/TimelineContextProvider";
import { DownOutlined, UploadOutlined } from "@ant-design/icons";
import { UserContext } from '../../context/UserContext';
import ExportPlant from "../plants/ExportPlant"; 
import PricingPlans from "../PricingPlans";

export default function Measurments({ measurments, dli_light, thresholds, plantData }: any) {
  const [modal2Open, setModal2Open] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false); 
  const { timeline, setTimeline } = useContext(TimelineContext);
  const { userDetails } = useContext(UserContext);
  const { Text } = Typography;
  
  let labels = [];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setTimeline(e.key);
  }

  const items: MenuProps['items'] = [
    {
      label: 'Week',
      key: 'week',
    },
    {
      label: 'Month',
      key: 'month',
    }
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  const currentDate = moment();
    if (timeline === 'week') {
        labels = Array.from({ length: 8 }, (_, i) =>
            currentDate.clone().subtract(i, 'days').format('MMM D YYYY')
        ).reverse();
    } else { // month
        labels = Array.from({ length: 31 }, (_, i) =>
            currentDate.clone().subtract(i, 'days').format('MMM D YYYY')
        ).reverse();
    }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.top}>
          <h1>Measurements</h1>
          <div className={styles.buttonsContainer}>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    paddingBlock: 20,
                    defaultBg: "#F6F7F9",
                    defaultBorderColor: "",
                    colorPrimary: userDetails?.role === 'admin' || userDetails?.role === 'university_customer' ? "#126D34" : "#757873",
                  },
                }
              }}>
              <Dropdown menu={menuProps}>
                <Button style={{
                  textTransform: 'capitalize',
                }}>
                  <Space>
                    {timeline}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              <ExportPlant plant={plantData} /> 
            </ConfigProvider>
          </div>
        </div>
        <div className={styles.chartsContainer}>
          <MoistureChart date={labels} measurments={measurments} thresholds={thresholds} plant={plantData} />
          <LightChart date={labels} dli_light={dli_light} thresholds={thresholds} plant={plantData} />
          <TempratureChart date={labels} measurments={measurments} thresholds={thresholds} plant={plantData} />
          <FertilityChart date={labels} measurments={measurments} thresholds={thresholds} plant={plantData} />
        </div>
      </div>
    </div>
  )
}
