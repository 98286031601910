import {Chart} from "react-chartjs-2";
import React, { useRef} from 'react'
import styles from "../../styles/plants/Charts.module.css";
import moment from "moment";
import {Chart as ChartJS, LinearScale, Tooltip, Legend, TimeScale, BarController} from "chart.js"; 
import 'chartjs-adapter-moment';
import { generateCandlestickData, createHeaderDateRange, stepSize, displayFormat } from "./ChartHelper";

ChartJS.register(LinearScale, Tooltip, Legend, TimeScale, BarController); 

export default function FertilityChartSmall({date, measurments, thresholds, plant}) {
    const rawData = measurments.map(each => ({
        x: moment.utc(each.date_utc).local(),
        y: each.soil_fertility,
    }))
    const hasData = rawData.length > 0;
    const chartRef = useRef(null);
    const headerDateRange = createHeaderDateRange(date);
    const fertility = generateCandlestickData(rawData);

    const data = {
        labels: date,
        datasets: [
            {
                label: 'Salinity',
                data: fertility,
                barThickness: 'flex',
                maxBarThickness: 5,
                borderRadius: 5,
                backgroundColor: '#454744',
                borderSkipped: false,
                minBarLength: 5,
            }
        ],
    };

    const options = {
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    display: false
                },
                position: 'right',
                min: 0.0,
                max: 3.0,
                ticks: {
                    align: 'start',
                    stepSize: 0.5,
                },
            },
            x: {
                grid: {
                    borderDash: [8, 4],
                    drawBorder: false,
                },
                type: 'time',
                time: {
                    displayFormats: {
                        day: displayFormat(date),
                    },
                    max: date[date.length - 1],
                    min: date[0],
                    stepSize: stepSize(date),
                },
                title: {
                    display: false,
                    text: 'Time'
                },
                ticks: {
                    font: {
                        weight: 'bold',
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: function() {
                    return 'Range'
                  },
                  label: function(context) {
                    const data = context.raw;
                    return data.y[0] + '-' + data.y[1] + ' mS/cm';
                  },
                  afterBody: function(context) {
                    const date = context[0].raw.x;
                    return 'Date taken: ' + moment(date).format('MMM D');
                  }
                }
              }
        },
    }

    return (
        <div className={styles.chartSmall}>
            {!hasData && <div className={styles.chartNoData}>No Salinity data</div>}
            {!!hasData && 
            <>
                <div className={styles.chartSmallHeaderContainer}>
                    <h3>
                        {thresholds.salinity_min_good} - {thresholds.salinity_max_good} 
                    </h3>
                    <p>
                        {headerDateRange}
                    </p>
                </div>
                <p>Optimal Range</p>
                <Chart ref={chartRef} type='bar' data={data} options={options} key={'BarSalinityChartSmall' + plant.id}/>
            </>}
        </div>
    )
}
