import { createContext, useReducer } from "react";

export const TokenContext = createContext();

const tokenReducer = (state, action) => {
  switch (action.type) {
    case "CREATE":
      return { token: action.payload };
    default:
      return state;
  }
};

export default function TokenContextProvider({ children }) {
  const [state, dispatch] = useReducer(tokenReducer, {
    token: null,
  });

  return (
    <TokenContext.Provider value={{ ...state, dispatch }}>
      {children}
    </TokenContext.Provider>
  );
}
