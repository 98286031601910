import React, {useState, useContext, useMemo, useEffect} from "react";
import styles from "../../styles/plants/Plants.module.css";
import Spinner from "../spinner";
import {ResponsePlantList} from "../../types/ResponsePlantList";
import { TimelineContext } from "../../context/TimelineContextProvider";
import { useIsFetching } from "@tanstack/react-query";
import PlantData from "./PlantData";
import { Dropdown, Button, Space, Tabs, ConfigProvider } from "antd";
import type { MenuProps } from 'antd';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { Tab } from "rc-tabs/lib/interface";
import { useInView } from "react-intersection-observer";
import NoPlants from "./NoPlants";
import EmptyGarden from "./EmptyGarden";
import ExportData from "./ExportData";

export default function Plant(props: { homeData:  ResponsePlantList, }) {
  const plants = props.homeData.plants;
  const gardens = props.homeData.gardens;
  const noPlants = plants.length === 0;
  const isFetching = useIsFetching();

  const PLANTS_PER_LOAD = 5;

  const [gardenId, setGardenId] = useState("0");
  const [visiblePlantsCount, setVisiblePlantsCount] = useState(PLANTS_PER_LOAD);
  const {timeline, setTimeline} = useContext(TimelineContext);
  const {ref, inView} = useInView();

  const filteredPlants = useMemo(() => {
    if (gardenId === "0") 
      return plants;
    return plants.filter(plant => plant.garden && plant.garden.id === Number(gardenId))
  }, [gardenId, plants])

  const visiblePlants = useMemo(() => {
    return filteredPlants.slice(0, visiblePlantsCount);
  }, [filteredPlants, visiblePlantsCount])

  
  const hasMorePlants = visiblePlantsCount < filteredPlants.length;

  const handleLoadMore = () => {
    setVisiblePlantsCount((prev) => prev + PLANTS_PER_LOAD);
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setTimeline(e.key);
    setVisiblePlantsCount(PLANTS_PER_LOAD)
  }

  const handleGardenChange = (gardenId: string) => {
    setGardenId(gardenId);
    setVisiblePlantsCount(PLANTS_PER_LOAD);
  }

  useEffect(() => {
    if (inView)
      handleLoadMore();
  }, [inView, gardenId, timeline])

  const items: MenuProps['items'] = [
    {
      label: 'Week',
      key: 'week',
    },
    {
      label: 'Month',
      key: 'month',
    },
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  function generateGardenTabs() {
    const allTab: Tab[] = [
      {
        key: '0',
        label: 'All',
      }]
    
    const gardensTabs: Tab[] = gardens.map((item: any, i) => {
      return {
        key: String(item.id),
        label: item.name,
      } as Tab
    })

    const tabs = allTab.concat(gardensTabs);
    return tabs
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.navContainer}>
          <div className={styles.nav}>
            {!!gardens && <Tabs
              defaultActiveKey="0"
              tabPosition="top"
              items= {generateGardenTabs()}
              onChange={(activateKey) => {
                handleGardenChange(activateKey)
              }}
            />}
          </div>
            <div className={styles.graphButtonsContainer}>
              <Dropdown menu={menuProps}>
                <Button>
                  <Space> 
                    {timeline}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              <ExportData homeData={props.homeData}/>
            </div>
        </div>
        <div className={styles.plantsHeader}>
            <h3 className={styles.name}>Name of Plant</h3>
            <div>
              <h3>Volumetric Water</h3>
              <span>Daily Average in %</span>
            </div>
            <div>
              <h3>Light DLI Trend</h3>
              <span>mol/day</span>
            </div>
            <div>
              <h3>Temperature</h3>
              <span>&#176;C</span>
            </div>
            <div>
              <h3>Salinity</h3>
              <span>mS/cm hourly average</span>
            </div>
            
        </div>
        <div>
          <div className={styles.container}>
            {noPlants ? (<NoPlants />) :
             (visiblePlants ? 
              (visiblePlants.length === 0 ? (<EmptyGarden/>) :
                (visiblePlants.map((plant: any, index: any) => (<PlantData plant={plant} key={index}/>)))) :
              (<Spinner />)
            )}
          </div>
          {!isFetching && hasMorePlants && <div ref={ref}></div>}
        </div>
      </div>
    </div>
  );
}