import axios from "axios";
import {ResponseMeasurements} from "../types/ResponseMeasurements";
import {ResponseAllMeasurements} from "../types/ResponseAllMeasurements";
import { Plant } from "../types/Plant";

function fetchWithAuthentication(url: string, authToken: string) {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return axios.get(url, { headers });
}

function fetchImageWithAuthentication(url: URL, authToken: string) {
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${authToken}`);
    return fetch(url, { headers });
}

export async function getStartupData(authToken: string) {
    const response = await fetchWithAuthentication(
        `${process.env.REACT_APP_BASEURL}/version/startup`,
        authToken
    );

    if (response.data.error) {
        throw new Error(response.data.error);
    }

    return response.data;
}



export async function getPlantsData(authToken: string) {
    const response = await fetchWithAuthentication(
        `${process.env.REACT_APP_BASEURL}/user-plant`,
        authToken
    );

    if (response.data.error) {
        throw new Error(response.data.error);
    }

    const plantList = [];
    for (let i = 0; i < response.data.plants.length; i++) {
        plantList[i] = response.data.plants[i];
        if (plantList[i].plant_thumb_path != null) {
            plantList[i].htmlImageSrc = plantList[i].plant_thumb_path;
        }

        // don't load
        // // don't load customer image with 10+ images on page
        // if (plantList[i].thumb_path != null && response.data.plants.length < 10) {
        //     const responseImage = await fetchImageWithAuthentication(
        //         plantList[i].thumb_path,
        //         authToken
        //     );
        //     // Convert the data to Base64 and build a data URL.
        //     const binaryData = await responseImage.arrayBuffer();
        //     if (binaryData) {
        //         // @ts-ignore
        //         const base64 = btoa(String.fromCharCode(...new Uint8Array(binaryData)));
        //         plantList[i].htmlImageSrc = `data:image/png;base64,${base64}`;
        //     }
        // }
    }

    const gardens = response.data.gardens.map((item: any, index: any) => ({
        name: item.garden_name,
        id: item.id,
    }));

    return { plants: plantList, gardens };
}

export async function getPlantData(id: string, authToken: string) {
    const response = await fetchWithAuthentication(
        `${process.env.REACT_APP_BASEURL}/user-plant/${id}`,
        authToken
    );

    if (response.data.error) {
        throw new Error(response.data.error);
    }

    let plant = response.data.plant;
    

    if (plant.plant_thumb_path != null) {
        plant.htmlImageSrc = plant.plant_thumb_path;
    }

    // don't load
    // if (plant.thumb_path != null) {
    //     const responseImage = await fetchImageWithAuthentication(
    //         plant.thumb_path,
    //         authToken
    //     );
    //     // Convert the data to Base64 and build a data URL.
    //     const binaryData = await responseImage.arrayBuffer();
    //     // @ts-ignore
    //     const base64 = btoa(String.fromCharCode(...new Uint8Array(binaryData)));
    //     plant.htmlImageSrc = `data:image/png;base64,${base64}`;
    // }

    return plant as Plant;
}

export async function getMeasurements(id: string, authToken: string, timeline: string) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
    };

    const formData = {
        search: {
            timeline: timeline,
        },
    };

    const response =  await axios.post(
        `${process.env.REACT_APP_BASEURL}/user-plant/measurements/${id}`,
        formData,
        { headers }
    );

    if (response.data.error) {
        throw new Error(response.data.error);
    }

    return response.data as ResponseMeasurements;
}

export async function getAllMeasurements(ids: number[] | string[], authToken: string, timeline: string) {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
    };

    const formData = {
        search: {
            timeline: timeline,
            userPlantList: ids
        },
    };

    return axios.post<ResponseAllMeasurements>(
        `${process.env.REACT_APP_BASEURL}/user-plant/measurements`,
        formData,
        { headers }
    );
}

export async function getListMeasurements(authToken: string, userPlantIds: number[], scanFromDate: string, scanToDate: string) {

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
    };

    const formData = {
        userPlantIds: userPlantIds,
        scanFromDate: scanFromDate,
        scanToDate: scanToDate
    };

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASEURL}/user-plant/list-measurements`,
            formData,
            { headers }
        );
        return response.data;
    }
    catch(error) {
        console.error("Error fetching data:", error);
    }
}
