import React from "react";
import ReactDOM from "react-dom/client";
import { 
  QueryClient, 
  QueryClientProvider} from '@tanstack/react-query';
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import UserContextProvider from "./context/UserContext";
import TokenContextProvider from "./context/TokenContext";
import TimelineContextProvider from "./context/TimelineContextProvider";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const queryClient = new QueryClient(
  {defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  },
});
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <UserContextProvider>
          <TimelineContextProvider>
            <TokenContextProvider>
              <GoogleOAuthProvider clientId={ID || ""}>
                <App />
              </GoogleOAuthProvider>
            </TokenContextProvider>
          </TimelineContextProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
