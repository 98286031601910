import { Layout, Typography } from "antd";
import Navbar from "../componets/Navbar";
import FooterFyta from "../componets/FooterFyta";
import styles from "../styles/Token.module.css";
import { useState, useEffect, useContext } from "react";
import { Footer } from "antd/lib/layout/layout";
import '@shoelace-style/shoelace/dist/themes/light.css'; 
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import { SlCopyButton } from '@shoelace-style/shoelace/dist/react';
import { AuthContext } from "../context/AuthContext";

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.0.0-beta.83/dist/');

const { Content } = Layout;
const { Title, Text } = Typography;

export default function APIToken() {
    const { user } = useContext(AuthContext);
    const [apiToken] = useState(user.access_token);

    useEffect(() => {
        import('@shoelace-style/shoelace/dist/components/copy-button/copy-button.js');
    }, []);

    return (
        <Layout className={styles.layout}>
            <Navbar />
            <Content className={styles.content}>
                <a href="#" className={styles.backLink} onClick={(e) => { e.preventDefault(); window.history.back(); }}>← Back</a>
                <div className={styles.headerContainer}>
                    <h1 className={styles.title}>API Token</h1>
                    <button
                        type="button"
                        className={styles.createTokenButton}
                        disabled = {true}
                    >
                        Create API Token
                    </button>
                </div>
                <div className={styles.description}>
                    <img src="/info.png" alt="Info" className={styles.infoIcon} />
                    <Text className={styles.descriptionText}>
                        Use API Tokens to access FYTA from other CI services or automation tools.
                    </Text>
                </div>
                <div className={styles.tokenContainer}>
                    <div className={styles.tokenHeader}>
                        <Text className={styles.tokenLabel}><b>Access Token</b></Text>
                        <SlCopyButton
                            value={apiToken}  
                            copyLabel="Click to copy" 
                            successLabel="Copied!"
                            errorLabel="Copy unsuccessful due to your browser.."
                        >
                            <button
                                slot="copy-button"
                                className={styles.copyButtonImage}
                            >
                                <img src="/apicopy.png" alt="Copy API Token" />
                            </button>
                        </SlCopyButton>
                    </div>
                </div>
                <div className={styles.tokenBox}>
                    <Text className={styles.token}>{apiToken}</Text>
                </div>
                <div className={styles.infoContainer}>
                    <Title level={4} className={styles.subTitle}>What you should know about the FYTA API</Title>
                    <Text className={styles.infoText}>
                        You can integrate FYTA Beam plant statuses into your applications via IFTTT. IFTTT allows you to integrate with over 700 different apps and services, including Homekit, Amazon Alexa, and Google Assistant. <a href="https://ifttt.com/fyta" target="_blank" className={styles.apiLink} rel="noopener noreferrer">Access it here <img src="/arrow-up-right.png" alt="Arrow" className={styles.arrowRight} /></a>.
                    </Text>
                    <ul className={styles.infoList}>
                        <li>1. You can find documentation about the IFTTT integration <a href="https://ifttt.com/fyta/documentation" target="_blank" className={styles.apiLink} rel="noopener noreferrer">here <img src="/arrow-up-right.png" alt="" className={styles.arrowRight} /></a>.</li>
                        <li>2. Please join our <a href="https://discord.gg/fyta" target="_blank" className={styles.apiLink} rel="noopener noreferrer">Discord Channel <img src="/arrow-up-right.png" alt="" className={styles.arrowRight} /></a>.</li>
                    </ul>
                </div>
            </Content>
            <Footer className={styles.footer}>
                <FooterFyta />
            </Footer>
        </Layout>
    );
}
