import {Chart} from "react-chartjs-2";
import React, { useRef, useEffect, useState} from 'react'
import { ConfigProvider, Button } from "antd";
import styles from "../../styles/plants/Charts.module.css";
import moment, { min } from "moment";
import {Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend, TimeScale, Filler, LineController, LineElement} from "chart.js"; 
import { generateBarColors, createHeaderDateRange, stepSize } from "./ChartHelper";
import 'chartjs-adapter-moment';
import { UploadOutlined } from "@ant-design/icons";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, TimeScale, Filler, LineController, LineElement); 

export default function LightChart({date, dli_light, thresholds, plant}) {

  const light = dli_light.map(each => ({
    x: moment.utc(each.date_utc).local().format('YYYY-MM-DD'),
    y: each.dli_light
  }));
  const chartType = 'dli_light';
  const hasData = light.length > 0;
  const chartRef = useRef(null);
  const [optimalColor, setOptimalColor] = useState("#6FD3A2")
  const [alertColor, setAlertColor] = useState("#FCBB5B")
  const [urgentColor, setUrgentColor] = useState("#F96767")

  const colors = generateBarColors(light, thresholds, chartType, optimalColor, alertColor, urgentColor);
  const headerDateRange = createHeaderDateRange(date);

  function toggleOptimalChip(){
    if(optimalColor==="#BABABB"){
      setOptimalColor("#6FD3A2")
    }else{
      setOptimalColor("#BABABB")
    }
  }
  function toggleAlertChip(){
    if(alertColor==="#BABABB"){
      setAlertColor("#FCBB5B")
    }else{
      setAlertColor("#BABABB")
    }
  }

  function toggleUrgentChip(){
    if(urgentColor==="#BABABB"){
      setUrgentColor("#F96767")
    }else{
      setUrgentColor("#BABABB")
    }
  }

  const data = {
      labels: date,
      datasets: [
          {
              label: 'DLI',
              data: light,
              barThickness: 'flex',
              maxBarThickness: 100,
              borderRadius: 5,
              backgroundColor: colors,  
          }
      ],
  };

  const options = {
    animation: false,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        y: {
            position: 'right',
        },
        x: {
            grid: {
                borderDash: [8, 4],
                drawBorder: false,
            },
            type: 'time',
            time: {
                unit: 'day',
                displayFormats: {
                    day: 'MMM D',
                },
                max: date[date.length - 1],
                min: date[0],
                stepSize: stepSize(date),
            },
            title: {
                display: false,
                text: 'Time'
            },
            ticks: {
                font: {
                    align: 'start',
                    weight: 'bold', 
                }
            }
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
          callbacks: {
            title: function() {
              return '';
            },
            label: function(context) {
              const data = context.raw;
              return data.y + ' mol/day';
            },
            afterBody: function(context) {
              const date = context[0].raw.x;
              return 'Date taken: ' + moment(date).format('MMM D');
            }
          }
        }
    },
}

  return (
    
      <div className={styles.chartContainer}>
          {!hasData && 
            <div className={styles.chartNoData}>
              <p className={styles.bigChartNoDataText}>No Light Data</p>  
            </div>}
          {!!hasData && 
          <>
            <div className={styles.header}>
              <div className={styles.headerTop}>
                <h3>DLI Light Trend</h3>
                <div className={styles.headerRight}>
                  <p>{headerDateRange}</p>
                  <Button icon={<UploadOutlined style={{color: "#33874A"}} />} size="large" />
                </div>
                
              </div>
              <span>Ideal Range: </span>
              <span className={styles.bold}>{thresholds.dli_light_min_good} - {thresholds.dli_light_max_good} mol/day</span>
            </div>
            <div className={styles.levels}>
              <span>Filter Status</span>
              <div className={styles.btn}>
                <button className={`${styles.btns} ${optimalColor==="#6FD3A2"? styles.active:""}`} onClick={toggleOptimalChip}>Optimal</button>
                <button  className={`${styles.btns} ${alertColor==="#FCBB5B"? styles.active:""}`} onClick={toggleAlertChip}>Alert</button>
                <button className={`${styles.btns} ${urgentColor==="#F96767"? styles.active:""}`} onClick={toggleUrgentChip}>Urgent</button>
              </div>
            </div>
            <Chart className={styles.chart} ref={chartRef} type='bar' data={data} options={options}  key={'BarChartLight_' + plant.id}/>
          </>}
      </div>
  )
}
