import { useState } from "react";
import styles from "../styles/forgotpassword.module.css";
import { Form, Button, Input} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessfulMinting from "../componets/Sucessfull";
import Spinner from "../componets/spinner";

export default function ForgotPassword() {

  const [loading, setLoading]=useState(false)
  const [success, setSuccess]= useState(false)
  const notify=()=>toast.error("an error was encountered")

  const onFinish = async (values:any) => {
    setLoading(true)

   const  formData = {
    email: values.email
   }
   try {
    const response = await axios.post(`${process.env.REACT_APP_BASEURL}/auth/password/request`,formData )
   setLoading(false)
    if(response.status === 204){
      setSuccess(true)
    }
    return
   } catch (error:any) {
    setLoading(false)

    if(error.response.data.error === "Not Found"){
    return  toast.error("this email is not registered",{position:"top-center"})
    }
    notify()
    return
   }

  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.card}>
            <div className={styles.profile}>
             <img src="/icons/icon-password-forget.svg" alt="Forgot Password?" width={42}/>
            <h3>Forgot Password?</h3>
                <div className={styles.forgetInfoText}>
                    Enter your registered email below to receive password reset instructions
                </div>
            </div>
          <div className={styles.inputs}>
          <Form onFinish={onFinish} initialValues={{ remember: true }}>
                <div className={styles.label}>E-mail</div>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Invalid email address. Please enter a valid email.",
                    },
                    {
                      required: true,
                      message: "Please enter your email address.",
                    },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>


                <Form.Item wrapperCol={{ span: 24 }} shouldUpdate>
                        <Button
                          style={{
                            width: "100%",
                            fontSize: "16",
                            fontWeight: "500",
                            backgroundColor:"#52CA8F",
                            color: '#313133',
                            marginTop: "20px",
                          }}
                          type="primary"
                          htmlType="submit"
                        >
                            Reset password
                        </Button>
                </Form.Item>
              </Form>
          </div>
          <div className={styles.user}>
            <Link to="/login">
                <img src="/icons/icon-back.svg" width={20} />
                &nbsp;&nbsp;&nbsp;Back to login</Link>
          </div>
          </div>
        </div>
        <ToastContainer />
       {success === true&& <SuccessfulMinting/>}
       {loading === true && <Spinner/>}
      </div>
    </>
  );
}

