import dayjs, { Dayjs } from 'dayjs';
import { useContext } from "react";
import { getListMeasurements } from "../../service/PlantsService";

const dateFormat: string = 'YYYY-MM-DD';

function convertToCSV(data: any) {
  let csvContent = "user_plant_id,light,temperature,soil_moisture,soil_fertility,date_utc\n";

  data.user_plants.forEach((userPlant: { measurements: any[]; user_plant_id: any; }) => {
    userPlant.measurements.forEach(measurement => {
        // Construct a CSV row
        let row = [
            userPlant.user_plant_id,
            measurement.light,
            measurement.temperature,
            measurement.soil_moisture,
            measurement.soil_fertility,
            measurement.date_utc
        ].join(",");
        csvContent += row + "\n";
    });
});

return csvContent;

}

function downloadFile(data: string, fileName: string, fileType: string) {
  const blob = new Blob([data], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

export async function handleExport(authToken: string, format: string, dateRange: [Dayjs, Dayjs], plant: number[]) {

  const startDate = dateRange[0].format(dateFormat);
  const endDate = dateRange[1].format(dateFormat);

  const data = await getListMeasurements(authToken, plant, startDate, endDate);

  if (!data) return;

  if (format === 'csv') {
    const csvData = convertToCSV(data);
    downloadFile(csvData, 'data.csv', 'text/csv');
  } else if (format === 'json') {
    const jsonData = JSON.stringify(data, null, 2);
    downloadFile(jsonData, 'data.json', 'application/json');
  }
  
}
