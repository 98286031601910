import React from "react";
import styles from "../styles/Congratsticket.module.css";

import { useNavigate } from "react-router-dom";

export default function SuccessfulMinting() {
  const navigate= useNavigate()

  return (
    <div className={styles.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "7px",
          background: "#FFFFFF",
          padding: "20px",
          width:"500px"
        }}
      >
        <img
          src="/closeblack.svg"
          alt="close"
          width="30px"
          height="30px"
          style={{ alignSelf: "flex-end", cursor: "pointer" }}
          className={styles.close}
          onClick={() =>navigate("/login")}
        />
        <div

            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
                borderRadius:"7px",
                background:"#FFFFFF",
                padding:"20px"
            }}
          >
            <div style={{paddingBottom:"50px",    
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            
            }}>
                <img src="/bigtick.svg" alt="success" 
                width="200px" height="200px"/>
                <h3 className="modalh3">Done!</h3>
                <p className="modalp">A mail has been sent to your mail box.</p>
            </div>

        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          
        </div>
      </div>
      </div>
  );
}

