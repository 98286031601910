import React from 'react';
import styles from '../styles/Avatar.module.css';
import { AvatarDropdown } from './AvatarDropdown';
import { Popover } from 'antd';

interface AvatarProps {
  username: string;
  email: string;
}

const Avatar: React.FC<AvatarProps> = ({ username, email }) => {
  const firstLetter = username.charAt(0).toUpperCase();

  return (
    <Popover 
      content={<AvatarDropdown username={username} email={email}/>}
      trigger="click"
      showArrow={false}
      color="#F6F7F9"
    >
      <div className={styles.avatar}>
        {firstLetter}
      </div>
    </Popover>
  );
};

export default Avatar;
