import {Chart} from "react-chartjs-2";
import React, { useRef, useContext, useState, useEffect} from 'react'
import { ConfigProvider, Button } from "antd";
import styles from "../../styles/plants/Charts.module.css";
import moment from "moment";
import {Chart as ChartJS, LinearScale, Tooltip, Legend, TimeScale, BarController} from "chart.js"; 
import { generateCandlestickData, createHeaderDateRange, createThresholdColor, stepSize } from "./ChartHelper";

import 'chartjs-adapter-moment';
import { UploadOutlined } from "@ant-design/icons";
import { TimelineContext } from "../../context/TimelineContextProvider";

ChartJS.register(LinearScale, Tooltip, Legend, TimeScale, BarController); 

export default function TempratureChart({date, measurments, thresholds, plant}) {

  const rawData = measurments.map(each => ({
    x: moment.utc(each.date_utc).local(),
    y: each.temperature,
  }))
  const { timeline } = useContext(TimelineContext);
  const chartType = 'temperature';
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const hasData = rawData.length > 0;
  const chartRef = useRef(null);
  const [optimalColor, setOptimalColor] = useState("#6FD3A2")
  const [alertColor, setAlertColor] = useState("#FCBB5B")
  const [urgentColor, setUrgentColor] = useState("#F96767")

  const headerDateRange = createHeaderDateRange(date);
  const temperature = generateCandlestickData(rawData);

  function toggleOptimalChip(){
    if(optimalColor==="#BABABB"){
      setOptimalColor("#6FD3A2")
    }else{
      setOptimalColor("#BABABB")
    }
  }
  function toggleAlertChip(){
    if(alertColor==="#BABABB"){
      setAlertColor("#FCBB5B")
    }else{
      setAlertColor("#BABABB")
    }
  }

  function toggleUrgentChip(){
    if(urgentColor==="#BABABB"){
      setUrgentColor("#F96767")
    }else{
      setUrgentColor("#BABABB")
    }
  }

  const data = {
      labels: date,
      datasets: [
          {
              label: 'Temperature',
              data: temperature,
              barThickness: 5,
              maxBarThickness: 5,
              borderRadius: 5,
              borderSkipped: false,
              minBarLength: 10,
          }
      ],
  };

  useEffect(() => {
    const chart = chartRef.current;

      if (!chart) {
          return;
      }

      const chartData = {
          ...data,
          datasets: data.datasets.map(dataset => ({
              ...dataset,
              backgroundColor : function() {
                const chart = chartRef.current;
                if (!!chart) {
                  return createThresholdColor(chart, thresholds, chartType, optimalColor, alertColor, urgentColor);
                }
                return 'BABABB'
              }
          })),
      };
      
      setChartData(chartData);

  }, [optimalColor, alertColor, urgentColor, date])

  const options = {
    animation: false,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        y: {
            position: 'right',
            min: -10,
            max: 50,
            ticks: {
                stepSize: 10,
            },
        },
        x: {
            grid: {
                borderDash: [8, 4],
                drawBorder: false,
            },
            type: 'time',
            time: {
                unit: 'day',
                displayFormats: {
                    day: 'MMM D',
                },
                max: date[date.length - 1],
                min: date[0],
                stepSize: stepSize(date),
            },
            title: {
                display: false,
                text: 'Time'
            },
            ticks: {
                font: {
                    align: 'start',
                    weight: 'bold', 
                }
            }
        },
    },
    plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: function() {
              return 'Range'
            },
            label: function(context) {
              const data = context.raw;
              return data.y[0] + '-' + data.y[1] + '°C';
            },
            afterBody: function(context) {
              const date = context[0].raw.x;
              return 'Date taken: ' + moment(date).format('MMM D');
            }
          }
        }
    },
}

  return (
    
      <div className={styles.chartContainer}>
          {!hasData && 
            <div className={styles.chartNoData}>
              <p className={styles.bigChartNoDataText}>No Temperature Data</p>  
            </div>}
          {!!hasData && 
          <>
            <div className={styles.header}>
              <div className={styles.headerTop}>
                <h3>Temperature</h3>
                <div className={styles.headerRight}>
                  <p>{headerDateRange}</p>
                  <Button icon={<UploadOutlined style={{color: "#33874A"}} />} size="large" />
                </div>
              </div>
              <span>Ideal Range: </span>
              <span className={styles.bold}>{thresholds.temperature_min_good} - {thresholds.temperature_max_good} &deg;C</span>
            </div>
            <div className={styles.levels}>
              <span>Filter Status</span>
              <div className={styles.btn}>
                <button className={`${styles.btns} ${optimalColor==="#6FD3A2"? styles.active:""}`} onClick={toggleOptimalChip}>Optimal</button>
                <button  className={`${styles.btns} ${alertColor==="#FCBB5B"? styles.active:""}`} onClick={toggleAlertChip}>Alert</button>
                <button className={`${styles.btns} ${urgentColor==="#F96767"? styles.active:""}`} onClick={toggleUrgentChip}>Urgent</button>
              </div>
            </div>
            <Chart className={styles.chart} ref={chartRef} type='bar' data={chartData} options={options}  key={'BarChartTemperature_' + plant.id}/>
          </>}
      </div>
  )
}
