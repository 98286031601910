import styles from "../styles/Common.module.css";

export default function FooterFyta() {
  return (
      <div className={styles.footer}>
        <div className={styles.footerLogo}>
            <img src="/fyta.png" alt="FYTA" className={styles.navLogo}/>
        </div>
        <div className={styles.footerLinks}>
        <div className={styles.footerColumn}>
          <a href='https://discord.gg/fVtheDkqCN' target='_blank' rel='noopener noreferrer'>Talk to us in discord</a>
          <a href='https://fyta.de/en/pages/help-center' target='_blank' rel='noopener noreferrer'>Help center</a>
          <a href='https://fyta.de/en/collections/all' target='_blank' rel='noopener noreferrer'>Buy more Beams</a>
        </div>
        <div className={styles.footerColumn}>
          <a href='https://fyta.de/en/blogs/news' target='_blank' rel='noopener noreferrer'>News</a>
          <a href='https://fyta.de/en/pages/contact-form' target='_blank' rel='noopener noreferrer'>Contact us</a>
          <a href='https://fyta.de/en/policies/legal-notice' target='_blank' rel='noopener noreferrer'>Impressum</a>
        </div>
      </div>
    </div>
  );
}
