import {Chart} from "react-chartjs-2";
import React, { useRef, useEffect, useState} from 'react'
import styles from "../../styles/plants/Charts.module.css";
import moment from "moment";
import {Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend, TimeScale, Filler, LineController, LineElement} from "chart.js"; 
import 'chartjs-adapter-moment';
import { createLineFillGradient, createHeaderDateRange, stepSize, displayFormat } from "./ChartHelper";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, TimeScale, Filler, LineController, LineElement); 

export default function MoistureChartSmall({date, measurments, thresholds, plant}) {
    const moisture = measurments.map(each => ({
        x: moment.utc(each.date_utc).local(),
        y: each.soil_moisture,
    }))

    const hasData = moisture.length > 0;
    const chartRef = useRef(null);
    const headerDateRange = createHeaderDateRange(date);
    const [chartData, setChartData] = useState({
        datasets: [],
      });

    const data = {
        labels: date,
        datasets: [
            {
                label: 'Moisture',
                data: moisture,
                pointRadius: 0,
                fill: true,
                borderColor: '#454744',
                lineTension: 0.2,
            }
        ],
    };

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        const chartData = {
            ...data,
            datasets: data.datasets.map(dataset => ({
                ...dataset,
                backgroundColor: createLineFillGradient(chart.ctx, chart.chartArea),
            })),
        };
        
        setChartData(chartData);
    }, [date]);

    const options = {
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    display: false
                },
                position: 'right',
                min: 0,
                max: 100,
                ticks: {
                    align: 'start',
                    stepSize: 20,
                    callback: function (value) {
                        return (' '+ value + '%')
                    },
                },
            },
            x: {
                grid: {
                    borderDash: [8, 4],
                    drawBorder: false,
                },
                type: 'time',
                time: {
                    displayFormats: {
                        day: displayFormat(date),
                    },
                    max: date[date.length - 1],
                    min: date[0],
                    stepSize: stepSize(date),
                },
                title: {
                    display: false,
                    text: 'Time'
                },
                ticks: {
                    font: {
                        weight: 'bold',
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: function() {
                    return '';
                  },
                  label: function(context) {
                    const data = context.raw;
                    return data.y + '%';
                  },
                  afterBody: function(context) {
                    const date = context[0].raw.x;
                    return 'Date taken: ' + moment(date).format('MMM D HH:mm');
                  }
                }
              }
        },
    }

    return (
        <div className={styles.chartSmall}>
            {!hasData && <div className={styles.chartNoData}>No Moisture data</div>}
            {!!hasData && 
            <>
                <div className={styles.chartSmallHeaderContainer}>
                    <h3>
                        {thresholds.moisture_min_good} - {thresholds.moisture_max_good}%
                    </h3>
                    <p>
                        {headerDateRange}
                    </p>
                </div>
                <p>Optimal Range</p>
                <Chart ref={chartRef} type='line' data={chartData} options={options}  key={'LineMoistureChartSmall' + plant.id}/>
            </>}
        </div>
    )
}
