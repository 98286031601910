import moment from "moment";

export function createLineFillGradient(ctx, area) {
    const colorStart = 'rgba(163,165,168,1)';
    const colorEnd = 'rgba(163,165,168,0)';
  
    const gradient = ctx.createLinearGradient(0, area.top, 0, area.bottom);
  
    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(0.2, colorStart);
    gradient.addColorStop(1, colorEnd);   
  
    return gradient;
  }

  export function createHeaderDateRange(dates) {

    const date1 = moment(dates[0], 'MMM D YYYY')
    const date2 = moment(dates[dates.length - 1], 'MMM D YYYY')
    if (date1.month() === date2.month()) {
        return date1.format('D.') + ' - ' + date2.format('D. MMM YYYY');
      } 
    else { // different months
      if (date1.year() === date2.year()) {
        return date1.format('D. MMM') + ' - ' + date2.format('D. MMM YYYY');
      } 
      else {
        return date1.format('D. MMM YYYY') + ' - ' + date2.format('D. MMM YYYY');
      }
    }
  }

  export function createExportDateRange(date1, date2) {

    if (date1.month() === date2.month()) {
      return date1.format('D.') + ' - ' + date2.format('D. MMM YYYY');
    } 
    else { // different months
      if (date1.year() === date2.year()) {
        return date1.format('D. MMM') + ' - ' + date2.format('D. MMM YYYY');
      } 
      else {
        return date1.format('D. MMM YYYY') + ' - ' + date2.format('D. MMM YYYY');
      }
    }
  }

  export function createThresholdColor(chart, thresholds, chartType, optimalColor, alertColor, urgentColor) {
    const ctx = chart.ctx;
    const yScale = chart.scales.y;  
    const maxAcceptable = yScale.getPixelForValue(thresholds[chartType + '_max_acceptable']);
    const maxGood = yScale.getPixelForValue(thresholds[chartType + '_max_good']);
    const minAcceptable = yScale.getPixelForValue(thresholds[chartType + '_min_acceptable']);
    const minGood = yScale.getPixelForValue(thresholds[chartType + '_min_good']);

    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = chart.canvas.width;
    offscreenCanvas.height = chart.canvas.height;
    const offscreenCtx = offscreenCanvas.getContext('2d');

    offscreenCtx.fillStyle = urgentColor;
    offscreenCtx.fillRect(0, 0, offscreenCanvas.width, maxAcceptable);
    offscreenCtx.fillStyle = alertColor;
    offscreenCtx.fillRect(0, maxAcceptable, chart.width, maxGood);
    offscreenCtx.fillStyle = optimalColor;
    offscreenCtx.fillRect(0, maxGood, chart.width, minGood);
    offscreenCtx.fillStyle = alertColor;
    offscreenCtx.fillRect(0, minGood, chart.width, minAcceptable);
    offscreenCtx.fillStyle = urgentColor;
    offscreenCtx.fillRect(0, minAcceptable, chart.width, chart.height);

    const pattern = ctx.createPattern(offscreenCanvas, 'no-repeat');

    return pattern;
  }

  export function generateBarColors(datapoints, thresholds, chartType, optimalColor, alertColor, urgentColor) {
    const maxAcceptable = thresholds[chartType + '_max_acceptable'];
    const maxGood = thresholds[chartType + '_max_good'];
    const minAcceptable = thresholds[chartType + '_min_acceptable'];
    const minGood = thresholds[chartType + '_min_good'];

    const colors = [];
    for (let i = 0; i < datapoints.length; i++) {
      const datapoint = datapoints[i].y;
      if (datapoint < minAcceptable) {
        colors.push(urgentColor);
      }
      else if (datapoint < minGood && datapoint >= minAcceptable) {
        colors.push(alertColor);
      }
      else if (datapoint <= maxGood && datapoint >= minGood) {
        colors.push(optimalColor);
      }
      else if (datapoint <= maxAcceptable && datapoint > maxGood) {
        colors.push(alertColor);
      }
      else {
        colors.push(urgentColor);
      }
    }

    return colors;
  }

  export function generateCandlestickData(data) {
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      let currentDay = data[i].x.dayOfYear();
      let tempDay = currentDay;
      let maxVal = -1000;
      let minVal = 1000;
      
      while(tempDay === currentDay) {
        if (data[i].y > maxVal) {
          maxVal = data[i].y;
        }
        if (data[i].y < minVal) {
          minVal = data[i].y;
        }
        i++;
        if (i >= data.length) {
          break;
        }
        tempDay = data[i].x.dayOfYear();
      }
      i--;
      const x = moment(currentDay, 'DDD DDDD').format('MMM D YYYY');
      let element = {};
      element.x = x;
      element.y = [minVal, maxVal];
      newData.push(element);
    }
    return newData;
  }

export const displayFormat = (date) => {
  if (date.length === 8) {
      return 'D';
  }
  else {
      return 'MMM D';
  }
}

export const stepSize = (date) => {
  if (date.length === 8) {
      return 1;
  }
  else {
      return 7;
  }
}