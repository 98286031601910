import {Chart} from "react-chartjs-2";
import React, { useRef, useEffect, useState} from 'react'
import { ConfigProvider, Button } from "antd";
import styles from "../../styles/plants/Charts.module.css";
import moment from "moment";
import {Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend, TimeScale, Filler, LineController, LineElement} from "chart.js"; 
import 'chartjs-adapter-moment';
import { createLineFillGradient, createThresholdColor, createHeaderDateRange, stepSize } from "./ChartHelper";
import { UploadOutlined } from "@ant-design/icons";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, TimeScale, Filler, LineController, LineElement); 

export default function MoistureChart({date, measurments, thresholds, plant}) {

  const moisture = measurments.map(each => ({
    x: moment.utc(each.date_utc).local(),
    y: each.soil_moisture,
  }))
  const chartType = 'moisture';
  const hasData = moisture.length > 0;
  const chartRef = useRef(null);
  const headerDateRange = createHeaderDateRange(date);
  const [chartData, setChartData] = useState({
      datasets: [],
    });
  const [optimalColor, setOptimalColor] = useState("#6FD3A2")
  const [alertColor, setAlertColor] = useState("#FCBB5B")
  const [urgentColor, setUrgentColor] = useState("#F96767")

  function toggleOptimalChip(){
    if(optimalColor==="#BABABB"){
      setOptimalColor("#6FD3A2")
    }else{
      setOptimalColor("#BABABB")
    }
  }
  function toggleAlertChip(){
    if(alertColor==="#BABABB"){
      setAlertColor("#FCBB5B")
    }else{
      setAlertColor("#BABABB")
    }
  }

  function toggleUrgentChip(){
    if(urgentColor==="#BABABB"){
      setUrgentColor("#F96767")
    }else{
      setUrgentColor("#BABABB")
    }
  }

  const data = {
      labels: date,
      datasets: [
          {
              label: 'Moisture',
              data: moisture,
              pointRadius: 0,
              fill: true,
              lineTension: 0.2,
          }
      ],
  };

  useEffect(() => {
      const chart = chartRef.current;

      if (!chart) {
          return;
      }

      const chartData = {
          ...data,
          datasets: data.datasets.map(dataset => ({
              ...dataset,
              backgroundColor: createLineFillGradient(chart.ctx, chart.chartArea),
              borderColor : function() {
                const chart = chartRef.current;
                if (!!chart) {
                  return createThresholdColor(chart, thresholds, chartType, optimalColor, alertColor, urgentColor);
                }
                return 'BABABB'
              }
          })),
      };
      
      setChartData(chartData);
  }, [date, optimalColor, alertColor, urgentColor]);

  const options = {
      animation: false,
      interaction: {
          mode: 'index',
          intersect: false,
      },
      scales: {
          y: {
              position: 'right',
              min: 0,
              max: 100,
              ticks: {
                  align: 'start',
                  stepSize: 20,
                  callback: function (value) {
                      return (' '+ value + '%')
                  },
              },
          },
          x: {
              grid: {
                  borderDash: [8, 4],
                  drawBorder: false,
              },
              type: 'time',
              time: {
                  unit: 'day',
                  displayFormats: {
                      day: 'MMM D',
                  },
                  max: date[date.length - 1],
                  min: date[0],
                  stepSize: stepSize(date),
              },
              title: {
                  display: false,
                  text: 'Time'
              },
              ticks: {
                  font: {
                      weight: 'bold',
                  },
              }
          },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: function() {
              return '';
            },
            label: function(context) {
              const data = context.raw;
              return data.y + '%';
            },
            afterBody: function(context) {
              const date = context[0].raw.x;
              return 'Date taken: ' + moment(date).format('MMM D HH:mm');
            }
          }
        }
      }  
    }

  return (
    
      <div className={styles.chartContainer}>
          {!hasData && 
            <div className={styles.chartNoData}>
              <p className={styles.bigChartNoDataText}>No Moisture Data</p>  
            </div>}
          {!!hasData && 
          <>
            <div className={styles.header}>
              <div className={styles.headerTop}>
                <h3>Volumetric Water Content</h3>
                <div className={styles.headerRight}>
                  <p>{headerDateRange}</p>
                  <Button icon={<UploadOutlined style={{color: "#33874A"}} />} size="large" />
                </div>
              </div>
              <span>Ideal Range: </span>
              <span className={styles.bold}>{thresholds.moisture_min_good} - {thresholds.moisture_max_good}%</span>
            </div>
            <div className={styles.levels}>
              <span>Filter Status</span>
              <div className={styles.btn}>
                <button className={`${styles.btns} ${optimalColor==="#6FD3A2"? styles.active:""}`} onClick={toggleOptimalChip}>Optimal</button>
                <button  className={`${styles.btns} ${alertColor==="#FCBB5B"? styles.active:""}`} onClick={toggleAlertChip}>Alert</button>
                <button className={`${styles.btns} ${urgentColor==="#F96767"? styles.active:""}`} onClick={toggleUrgentChip}>Urgent</button>
              </div>
            </div>
            <Chart className={styles.chart} ref={chartRef} type='line' data={chartData} options={options}  key={'LineMoistureChartSmall' + plant.id}/>
          </>}
      </div>
  )
}
